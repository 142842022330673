<template>
    <div class="wrapper">
      <div class="content">
        <h3>The Hillstead Farms Mission</h3>
        <p class="p-content">Hillstead Farms exists to produce quality, delicious food without the use of herbicides or pesticides. Through fair pricing, we strive to make fresh food more accessible to our customers. In this way, we hope to educate and foster the health of our local community, as well as continue the proud legacy of small, family farming in Kentucky. </p>
      </div>
    </div>
</template>

<script>
export default {
    name: 'MissionPage',
}
</script>

<style>
.contact {
  text-align: center;
}

.contact,
.p-content {
  font-family: 'Montserrat', sans-serif;
}
.p-content {
  text-align: left;
  margin-left: 45px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  height: 100%;
  padding-bottom: 20px;
}
.wrapper {
    height: 100%;
}
@media screen and (min-width: 800px) {
  .p-content {
    font-size: 18px;
    width: 60%;
    margin: auto;
  }
}

</style>