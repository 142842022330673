<template>
    <div class="wrapper">
      <div class="content">
        <h3>Our Story</h3>
        <p class="p-content">In the fall of 2018, we purchased a former family tobacco farm in Taylorsville, Kentucky with dreams of a life more connected to the land. Through various permaculture techniques, we continuously strive to restore the health of the soil, waterways, and plant and animal populations on our farm. In the short time we’ve been here, we have been rewarded with dramatic recovery in all the areas mentioned above. This has encouraged us to continue on this path.</p>
        <p class="p-content">
        2018 was also the year that we attended our first Mt. Washington Farmer's Market, where we immediately felt welcomed. It is more than a place to buy Kentucky produce and crafted products. It is, at its core, a gathering place where the community supports its local farmers and artisans. Now in its 11th year, the market was begun by a group of dedicated volunteers with a vision. It has grown over the years into the phenomenal market it is today.
        </p>
        <p class="p-content">In 2020, the COVID-19 pandemic dramatically affected our lives, as it did the lives of people all over the world. It forced us to focus intensely on our core values, and the ways in which we might support our community. Through the skills we have gained through permaculture, we decided that providing a source for nutritious food was one way we could help.</p>
        <p class="p-content">In 2021, after months of research and planning, we began building our growing operation for microgreens. Microgreens provide many important nutrients for a healthier life in a small package. Adding microgreens to foods we already consume regularly, such as sandwiches, soups, salads, smoothies, and more, enhances flavor as well as overall nutrition. In other words, no dramatic changes in lifestyle are required. Microgreens are a simple solution to improving overall nutrition, and enjoying tastier food.</p>
        <p class="p-content">
        This year we are proud to be vending at the Mt. Washington Farmer's Market where our locally homegrown and organic microgreens can find their way home to the many people who continue to support small family-run businesses. We look forward to the future as we continue to focus on growing and creating even more quality products to support the health and happiness of our community.</p>
        
        <p class="p-content">If you have any questions, click <a href="#contact-page">here</a> to be redirected to our Contact Us page, and send us a message.</p>

      </div>
    </div>
</template>

<script>
export default {
    name: 'AboutPage',
}
</script>

<style>
.contact {
  text-align: center;
}

.contact,
.p-content {
  font-family: 'Montserrat', sans-serif;
}
.p-content {
  text-align: left;
  margin-left: 45px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  height: 100%;
  padding-bottom: 20px;
}
.wrapper {
    height: 100%;
}
@media screen and (min-width: 800px) {
  .p-content {
    font-size: 18px;
    width: 60%;
    margin: auto;
  }
}

</style>