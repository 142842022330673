<template>
    <div class="content">
        <h3 class="title-card">Contact Us</h3>
        <a href="mailto:info@hillsteadfarmsky.com"><p class="child2-title">via email: info@hillsteadfarms.ky.com</p></a>
    </div>
</template>

<script>
export default {
    name: 'Contact',
}
</script>

<style scoped>
a:link {
    text-decoration: none;
}
</style>