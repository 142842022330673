<template>
    <div class="wrapper">
      <div class="content">
        <h3>Our Microgreens</h3>
        <p class="p-content">Grown straight from our farm, our organic microgreens boast equal parts flavor, nutrition, and quality.  Microgreens make a delicious and crunchy addition to burgers, sandwiches, tacos, salads, and more.  They can also boost flavor and nutrition in smoothies and juices, as well as pesto and other sauces.</p>
        <div v-for="microgreen in microgreens" :key="microgreen.id" class="flex-parent">
            <div class="flex-child">

                <!--<span><img src="../assets/images/microgreens-2021/wheatgrass.jpg" class="image"/></span>
                <span><img src="`../assets/images/microgreens-2021/wheatgrass.jpg`" class="image"/></span>
                <span><img src="/images/wheatgrass.jpg" class="image"/></span>-->
                <span><img v-bind:src="`/images/${microgreen.src}`" class="image"/></span>

            </div>
            <div class="flex-sibling">
                <span class="title">{{microgreen.name}}</span>
                <span class="price">${{microgreen.price[0]}} per  {{microgreen.price[1]}}</span>
                <div class="description">
                    <span>{{microgreen.description}}</span>
                </div>
            </div>
            <div class="cart">
                <span><button @click="incrementCart()">+</button></span>
                <span>{{count}}</span>
                <span><button @click="decrementCart()">-</button></span>
            </div>
        </div>

        <!--<img src="../assets/images/microgreens-2021/sunflower.jpg"/>
        <img :src="require('../assets/images/microgreens-2021/' + microgreen.src)"/>

        <img :src="`../assets/images/microgreens-2021/${microgreen.src}`" :alt="microgreen.name">
                    <img :src="require('../assets/images/microgreens-2021/' + {microgreen.src} + '.jpg')"/><!-->

      </div>
    </div>
</template>

<script>
import content from '/src/data/site-data.js';

var microgreens = content.microgreens;
var count = 0;

export default {
    name: 'ProductPage',
    data() {
        return {
            microgreens,
            count,
        }
    },
    mounted: function() {
        console.log("ProductPage mounted");
        this.test();
    },
    methods: {
        test: function() {
            if(microgreens) {
                console.log(microgreens)
            }
            if(!microgreens) {
                return "loading";
            }
        },
        incrementCart: function() {
            count += 1;
            console.log("count is", count);
        },
        decrementCart: function() {
            if(count == 0) {
                console.log("count is", count);
            }
            if(count >= 1) {
                count -= 1;
                console.log("count is", count);
            }
        },
        // fixDecimal(number) {
        //     var calculatedPrice = accounting.formatMoney(number);
        //     return calculatedPrice;
        // },
        // getMicrogreenImage(microgreenImage) {
        //     return require('../assets/images/microgreens-2021/' + microgreenImage)
        // }
    }
}
</script>

<style scoped>
.cart {
    display: none;
    flex-direction: column;
    align-self: center;
}
.description {
    align-self: flex-end;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    text-align: center;
    margin-left: 10px;
    font-size: 16px;
}
.flex-child {
    align-self: center;
}
.flex-parent {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;
    width: 85vw;
    border-bottom: 1px solid rgb(153,162,145);
}
.flex-sibling {
    display: flex;
    flex-direction: column;
}
.image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    filter: brightness(110%);

}
.title {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    padding-bottom: 20px;
}
.price {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bolder;
    letter-spacing: 1px;
}

@media screen and (min-width: 800px) {
.flex-parent {
    justify-content: center;
}
.flex-sibling {
    width: 500px;

}
}
</style>