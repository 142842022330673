<template>
  <div id="app">
    <div class="navbar">
        <div @click="menuVisible = !menuVisible"
              class="nav-item-container">
          <img src="./assets/images/menu-icon-transparent.png" class="nav-icon" /> <h5>menu</h5>
          <div v-if="menuVisible" class="dropdown-menu">
            <!--
            <a href="#main-page"><h2 class="nav-item">Events</h2></a>-->
            <a href="#product-page"><h2 class="nav-item">Products</h2></a>
            <a href="#contact-page"><h2 class="nav-item">Contact Us</h2></a>
            <a href="#mission-page"><h2 class="nav-item">Our Mission</h2></a>
            <a href="#about-page"><h2 class="nav-item">About Us</h2></a>
            <!--<a href="#microgreen-about"><h2 class="nav-item">Microgreen FAQ</h2></a>
            <a href="#recipes"><h2 class="nav-item">Recipes</h2></a>-->
          </div>
        </div>
    </div>
    <div class="header">
      <div class="container">
        <h1>Hillstead Farms</h1>
        <h2>From our Farm to your Family</h2>
      </div>
      <a href="#main-page"><img src="./assets/images/down-arrow-white.png" class="arrow-indicator"/></a>
    </div>
    <HomePage />
    <footer>
      <FooterContent />
    </footer>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue';
import FooterContent from './components/FooterContent.vue';

export default {
  name: 'App',
  components: {
    HomePage,
    FooterContent,
  },
  data() {
    return {
      menuVisible: false,
    }
  },
}
</script>

<style scss>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgb(153,162,145);
  color: #1E0B08;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
}
footer {
  color: white;
  margin: 40px 0 0 0;
  padding: 10px;
}
h1 {
  font-family: 'Crimson Text', serif;
  letter-spacing: 5px;
  font-weight: lighter;
  color: white;
  font-size: 45px;
  text-shadow: 3px 2px black;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  color: rgb(52,61,82);
  font-size: 16px;
  letter-spacing: 1px;
  margin: auto;
  background: rgb(250,250,250, 0.6);
  width: 80%;
  padding: 10px;
}
h5 {
  padding-left: 2px;
  color: rgb(52,61,82);
  z-index: 100;
  color: black;
  font-size: 12px;
}
.arrow-indicator {
  width: 50px;
  margin: auto;
  border-radius: 50%;
}
.arrow-indicator:hover {
  background: rgb(250, 250, 250, .3);
}
.container {
  margin: auto;
  padding: 20px;
  background: rgb(250,250,250, 0.3);
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
  width: 300px;
  background: rgb(235,235,235, .9);
  color: black;
  position: absolute;
  padding-left: 10px;
  padding-top: 60px;
  padding-bottom: 12px;
  padding-right: 12px;
  z-index: 20;
  text-align: left;
  margin-left: 5px;
}
.header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image:
  linear-gradient(to bottom, rgb(250,250,250, .5), rgb(250,250,250, .5)), url("./assets/images/barn.jpg");
  background-repeat: no-repeat;
  background-position: 10% 30%;
  background-size: cover;
}
.navbar {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  color: white;
  width: 100%;
  position: fixed;
  z-index: 99;
}
.nav-icon {
  width: 20px;
  height: 30px;
  align-self: center;
  z-index: 100;
  padding-left: 10px;
}
.nav-item {
  background: none;
  font-size: 20px;
  font-weight: lighter;
}
.nav-item:hover {
  background: white;
}
.nav-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.nav-item-container:hover {
  width: 80px;
  background: rgb(235,235,235, .9);
}
@media screen and (min-width: 700px) {
  h1 {
  letter-spacing: 5px;
  font-size: 90px;
  text-shadow: 3px 2px black;
  padding: 10px;
  height: 25%;
  }
  h2 {
    font-size: 30px;
    padding: 10px;
    background: rgb(250,250,250, 0.05);
  }
  footer {
    margin-top: 100px;
  }
  .container {
    margin-top: 150px;
    background: rgb(250,250,250, 0.3);
    height: 40%;
  }
  .dropdown-menu {
    margin-left: 0;
  }
}
</style>