<template>
  <div>
    <div class="margin-top" id="main-page">
      <div class="main-page-wrapper">
                <h1 class="child-text">Buy Our Microgreens at the:</h1>
                <div class="container-event-image">
                  <img src="../../public/images/sunflower.jpg" class="event-image"/>
                </div>
        <div class="content main-page-desktop-view">
          <div class="content-child content-child1">
            <a href="https://www.mtwashingtonky.org/community/farmers-market/" target="_blank"><h3 class="child-subtext link-hover">Mount Washington Farmer's Market</h3></a>
            <a href="https://www.mtwashingtonky.org/community/farmers-market/" target="_blank">
              <a href="https://goo.gl/maps/ZJbRoGEBuPoxtPT6A" target="_blank"><div class="address link-hover">
                <p class="address-p">Mt Washington City Plaza</p>
                <p class="address-p">300 Snapp St,</p>
                <p class="address-p">Mt Washington, KY 40047</p>
              </div>
              </a>
            </a>
          </div>
          <div class="content-child content-child2">
            <h3 class="title-card address-p">Jun. 5 - Sept. 11</h3>
            <p class="child2-title address-p">Wednesdays 4p - 7p</p>
            <p class="child2-title address-p">Saturdays 8a-12p</p>
          </div>
          <div class="content-child content-child3">
            <Contact />
          </div>
        </div>
      </div>

      <MissionPage
        id="mission-page"/>
      <ProductPage 
        id="product-page" />
      <AboutPage
        id="about-page" />
      <!--<MicrogreenAbout
        id="microgreen-about" />
      <Recipes
        id="recipes" />-->
      <Contact
        id="contact-page" />

    </div>
  </div>
</template>

<script>
// import MicrogreenAbout from './MicrogreenAbout.vue';
// import Recipes from './Recipes.vue';
import AboutPage from './AboutPage.vue';
import MissionPage from './MissionPage.vue';
import ProductPage from './ProductPage.vue';
import Contact from './Contact.vue';

// var microgreens = [content.microgreenImages];

export default {
  name: 'HomePage',
  components: {
    // MicrogreenAbout,
    // Recipes,
    AboutPage,
    MissionPage,
    ProductPage,
    Contact,
  },
  methods: {
    test() {
      console.warn("this is a test");
    }
  }
}
</script>

<style>
a:link {
    text-decoration: none;
}
h3 {
  font-family: 'Quicksand', sans-serif;;
  font-size: 25px;
  padding-top: 20px;
  color: #1E0B08;
  margin: auto;
}

h4 {
  font-family: 'Quicksand', sans-serif;;
  font-size: 20px;
  border-top: 1px solid rgb(153,162,145);
  padding-top: 20px;
  color: #1E0B08;
}
p {
  font-size: 20px;
}
.address-p {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 1px;
  color: black;
}
.container-event-image {
  background: white;
  margin: auto;
}
.child-text {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 40px;
}
.child2-title {
  font-face: 20px;
}
.content,
.slideshow-container {
  background: white;
  padding: 20px 20px 50px 20px;
  margin: 5px;
  height: 100%;
}
.content-header {
  height: 100%;
  width: 100%;
  margin: auto;
  opacity: .8;
}
.event-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 30px;
  margin: auto;
}
.facebook-icon,
.instagram-icon {
  width: 30px;
  height: 30px;
  align-self: center;
}
.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: .8;
}
.image-main {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: auto;
  border-radius: 50%;
  padding: 20px;

}
.image-text {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  background: rgb(250,250,250, 0.5);
  text-align: center;
  font-size: 40px;
  padding: 15px 10px;
}
.main-page {
  background-color: rgb(153,162,145);
  margin: 0;
  width: 100%;
  margin-top: 20%;
}
.main-page-wrapper {
  width: 100%;
}
.slideshow-container {
  background-color: white;
}
.subtext {
  font-size: 22px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bolder;
  color: black;
  background: none;
}
@media screen and (min-width: 800px) {
  h3 {
    width: 90%;
    border: none;
    font-size: 22px;
    padding-bottom: 10px;
  }
  p {
    font-size: 20px;
    line-height: 15px;
  }
  .address-p {
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    margin: auto;
    color: black;
  }
  .child-text {
    font-family: 'Dawning of a New Day', cursive;
    font-size: 60px;
    font-weight: lighter;
    text-shadow: 1px 1px black;
    color: #394B3B;
    background-color: white;
    margin: 5px 5px 0 5px;
    padding: 0;
  }
  .child2-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: auto;
    color: black;
    padding: 10px;
  }
  .child-subtext {
    font-family: 'Crimson Text', serif;
    font-size: 40px;
    background-color: #EDEDED;
    color: #1E0B08;;
    text-shadow: 1px 1px #EDEDED;
    font-weight: lighter;
    border: 1px
  }
  .content {
    margin: 0 5px;

    padding: 20px 20px 20px 20px;
    justify-content: space-between;
  }
  .container-event-image {
    margin-left: 5px;
    margin-right: 5px;
  }
  .content-child {
    width: auto;
  }
  .content-child1 {
    order: 2;
  }
  .content-child2 {
    order: 1;
    width: 30%;
    align-self: center;
  }
  .content-child3 {
    order: 3;
    width: 30%;
    align-self: center;
  }
  a:visited {
    color: black;
}
  .main-page-wrapper {
    width: 100%;
  }
  .main-page-desktop-view {
    height: 350px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    border: none;
  }
  .margin-top {
    width: 100%;
  }
  .slideshow-container {
    padding: 5px;
    margin: 5px 5px 0 5px;
  }
  .wrapper {
    height: auto;
  }
  .title-card {
    border-bottom: 1px dotted rgb(153,162,145);
  }
}
</style>
