const content = {
    microgreens: [
        {
            id: 1,
            name: 'Wheatgrass',
            src: 'wheatgrass-tall.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'Ideal for juicing with its bold green flavor.',
          },
          {
            id: 2,
            name: 'Sunflower',
            src: 'sunflower.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'Tastes just like sunflower seeds! Snappy and robust leaves.'
          },
          {
            id: 3,
            name: 'Pea',
            src: 'pea-short.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'Tastes just like peas! Surprisingly sweet.',
          },
          {
            id: 4,
            name: 'Radish',
            src: 'radish.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'Tastes just like radishes! Succulent leaves are peppery and spicy.',
          },
          {
            id: 5,
            name: 'Spicy Mix',
            src: 'spicy-mix.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'A blend of broccoli, kale, kolrabi, arugula, cabbage, and mustard green sprouts. Spicy and vibrant.',
          },
          {
            id: 6,
            name: 'Beet',
            src: 'beets2.jpg',
            inStock: true,
            price: ["1.50", "oz"],
            description: 'Tastes just like beets! Mild, understated, and delicate flavor',
          }
    ],

}

export default content;
