<template>
    <div>
        <div class="footer-content-div">
            <a href="#contact-page" class="footer-item">Contact Us</a>
            <a href="#mission-page" class="footer-item">Our Mission</a>
            <a href="#main-page" class="footer-item">Events</a>
            <a href="#product-page" class="footer-item">Our Microgreens</a>
            <a href="#about-page" class="ordered footer-item">The HillStead Story</a>
            <!--<a href="#microgreen-about" class="footer-item">Microgreen FAQ</a>-->
            <a href="" class="logo facebook-logo footer-item"><img src="../assets/images/facebook-icon.png" class="facebook-logo" /></a>
            <a href="" class="logo footer-item"><img src="../assets/images/instagram-icon.png" class="instagram-logo" /></a>
            <!--<a href="" class="footer-item">Resources</a>
            <a href="" class="footer-item">Explore</a>
            <a href="" class="footer-item">Recipes</a>-->
        </div>
        <div class="copywrite">
            <p>Site created and owned by Goat Farm LLC&#169;</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterContent',
}
</script>

<style>
.footer-item {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    color: #1E0B08;
    background: rgb(250, 250, 250, .8);
    margin: 5px;
    padding: 10px;
    height: auto;
    align-self: center;
    font-weight: bolder;
    letter-spacing: 1px;
}
.copywrite {
    letter-spacing: 1.5px;
    font-size: 12px;
    margin-top: 20px;
    border-top: 1px solid white;
    padding: 10px;
    font-weight: lighter;
    color: #1E0B08;
}
.facebook-logo {
    width: 30px;
}
.footer-content-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
}
.instagram-logo {
    width: 30px;
}
.logo {
}

</style>